import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import Drag from './new/Drag';
// const DATA = [
//   // { id: "todo-0", name: "Study React", completed: true },
// ];

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <App tasks={DATA}/>
  <App />
  // <Drag />
);
